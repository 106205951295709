import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Embed from "./Components/Embed";
import Layout from "./Components/Layout";
import Home from "./Components/Pages/Home";
import PrivateRoute from "./Components/PrivateRoute";
import Redirect from "./Components/Redirect";
import { DEFAULT_NAVBAR } from "./constants.js";
import routes from "./routes";

function App() {
  const [accessToken, setAccessToken] = useState(null);

  return (
    <Routes>
      <Route path="/" element={<Redirect />} />
      <Route path="/blank" element={<div></div>} />
      <Route
        path="/login"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.id}
          element={
            <PrivateRoute>
              <Layout
                setAccessToken={setAccessToken}
                accessToken={accessToken}
                withBackgroundNavbar={route.navbar || DEFAULT_NAVBAR}
              />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <Embed
                id={route.id}
                groupId={route.groupId}
                accessToken={accessToken}
                fit={route.scroll ? "fitToWidth" : "fitToPage"}
                width={route.width}
                backgroundColor={route.backgroundColor}
              />
            }
          />
        </Route>
      ))}
      {routes.map((route) => (
        <Route
          path={route.path + "-bis"}
          key={route.id}
          element={
            <PrivateRoute>
              <Layout
                setAccessToken={setAccessToken}
                withBackgroundNavbar={route.navbar || DEFAULT_NAVBAR}
              />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              <Embed
                id={route.id}
                groupId={route.groupId}
                accessToken={accessToken}
                fit={route.scroll ? "fitToWidth" : "fitToPage"}
                width={route.width}
                backgroundColor={route.backgroundColor}
              />
            }
          />
        </Route>
      ))}
      {/* <Route
        path="/compack"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/fc"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/fs"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/me"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/aidm"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/pe"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/sa"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/octa"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/sr"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/trs"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route>
      <Route
        path="/ux"
        element={
          <PrivateRoute>
            <Layout setAccessToken={setAccessToken} />
          </PrivateRoute>
        }
      >
        <Route index element={<Home />} />
      </Route> */}
    </Routes>
  );
}

export default App;
